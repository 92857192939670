<template>
  <div
    id="domestic-bar-charts"
    :style="{width: '100%', height: '600px',margin:'auto'}"
  />
</template>

<script lang="ts">
import { computed, defineComponent, getCurrentInstance, onMounted, watchEffect } from 'vue'

export default defineComponent({
  name: 'DomesticBarCharts',
  props: ['data'],
  setup (props) {
    const { proxy }: any = getCurrentInstance()
    const x = computed<string[]>(() => {
      const arr: string[] = []
      props.data.forEach((item: any, index: number) => {
        if (index < 10) arr.push(item.value)
      })
      return arr.reverse()
    })
    const y = computed<string[]>(() => {
      const arr: string[] = []
      props.data.forEach((item: any, index: number) => {
        if (index < 10) arr.push(item.name)
      })
      return arr.reverse()
    })
    onMounted(() => {
      const domesticBarCharts = proxy.$echarts.init(document.getElementById('domestic-bar-charts'))
      const render = () => {
        domesticBarCharts.setOption({
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {},
          yAxis: {
            data: y.value
          },
          series: [
            {
              name: '省份',
              type: 'bar',
              data: x.value,
              barWidth: 30,
              label: {
                show: true,
                position: 'right'
              },
              itemStyle: {
                normal: {
                  color: (params: any) => {
                    const colorList = [
                      '#C1232B', '#B5C334', '#FCCE10', '#E87C25', '#27727B',
                      '#FE8463', '#9BCA63', '#FAD860', '#F3A43B', '#60C0DD',
                      '#D7504B', '#C6E579', '#F4E001', '#F0805A', '#26C0C0'
                    ]
                    return colorList[params.dataIndex]
                  }
                }
              }
            }
          ]
        })
      }
      watchEffect(() => {
        render()
      })
      setTimeout(() => {
        domesticBarCharts.resize()
        window.addEventListener('resize', () => domesticBarCharts.resize())
        // window.onresize = () => {
        //   mapCharts.resize()
        // }
      }, 200)
    })
  }
})
</script>
